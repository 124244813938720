import { Reducer } from "redux";

import { EligibilityRecordResponse } from "../models/Api/Master";
import { EligibilityRecordActionDispatchTypes } from "../constants/Master";
import { EligibilityRecordDispatchTypes } from "../models/DispatchTypes/Master";

const initialState: EligibilityRecordResponse = {
  loading: false,
  response: {
    healthPlanId: 0,
    benefitPlanId: 0,
    subscriberId: "",
    mbi: "",
    healthPlan: "",
    product: "",
    benefitPlanNm: "",
    benefitPlanName: "",
    benefitPlanUid: "",
    healthPlanState: "",
    lengthOfEpisode: 0,
    healthPlanUid: "",
    eligibilityStatus: "",
    languagePreferenceValue: null,
    cellPhone: null,
    apiEligibilityId: "",
    alternatePhone: null,
    contractIdPbp: null,
    accommodationName: null
  },
};

const EligibilityRecordReducer: Reducer<
  EligibilityRecordResponse,
  EligibilityRecordDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case EligibilityRecordActionDispatchTypes.ELIGIBILITY_RECORD_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case EligibilityRecordActionDispatchTypes.ELIGIBILITY_RECORD_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case EligibilityRecordActionDispatchTypes.ELIGIBILITY_RECORD_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.response,
      };
    }
    case EligibilityRecordActionDispatchTypes.ELIGIBILITY_RECORD_RESET: {
      return initialState;
    }
  }
  return state;
};

export default EligibilityRecordReducer;
