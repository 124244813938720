import { Reducer } from "redux";

import { HealthPlanActionDispatchTypes } from "../constants/Master";
import { HealthPlanMasterResponse } from "../models/Api/Master";
import { HealthPlanDispatchTypes } from "../models/DispatchTypes/Master";

export const initialState: HealthPlanMasterResponse = {
  loading: false,
  response: [
    {
      healthPlanId: 0,
      healthPlanName: "",
      payerId: "",
      payerName: ""
    },
  ],
};

const HealthPlanReducer: Reducer<
  HealthPlanMasterResponse,
  HealthPlanDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case HealthPlanActionDispatchTypes.HEALTH_PLAN_MASTER_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case HealthPlanActionDispatchTypes.HEALTH_PLAN_MASTER_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default HealthPlanReducer;
